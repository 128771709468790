<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {userService} from "@/services/user.service";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Gardes Pharmaceutiques",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      editing: false,
      users: [],
      user: {},
      countries: [
        {
          id: 1,
          code: 'tg',
          name: 'Togo'
        },
        {
          id: 2,
          code: 'gh',
          name: 'Ghana'
        }
      ],
      title: "Compte administrateurs",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Compte",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "email", sortable: true },
        { key: "country", label: "Pays", sortable: true },
        { key: "actions", thStyle: { width: "20%" } },
      ],
      submitted: false
    };
  },
  validations: {
    user: {
      name: { required },
      email: { required, email  },
      country: { required },
      password: { required, minLength: minLength(8) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    }
  },
  computed: {
    rows() {
      return this.users.length;
    }
  },
  mounted() {
    this.getUsers();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let result = this.user.id ? await userService.update(this.user.id, this.user) : await userService.create(this.user);
        if (result >= 400) {
          Swal.fire("Erreur", "Une erreur s'est produite !", "warning");
        } else {
          Swal.fire(this.user.id ? 'Modification' : 'Enregistrement', "Opération reussie !", "success")
          await this.getUsers();
          this.clearForm();
        }
      }
    },
    clearForm() {
      this.submitted = false;
      this.editing = false;
      this.user = {};
    },
    async getUsers() {
      this.users = await userService.get();
    },
    remove(id) {
      Swal.fire({
        title: "Suppression",
        text: `Vous êtes sûr de supprimer le compte de ${this.user.name} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non"
      }).then(async result => {
        if (result.value) {
          let result = await userService.remove(id);
          if (result.status === 200) await Swal.fire("Suppression", "Compte supprimé avec succès !", "success");
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items">
      <button v-if="!editing" type="button" class="btn btn-success" @click="editing = true">Nouveau compte</button>
    </PageHeader>

    <div v-if="editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouveau compte</h4>
            <p class="card-title-desc">Définir une nouvelle période de garde via le formulaire</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="country">Pays</label>
                    <select v-model="user.country" id="country" class="form-select"
                            :class="{ 'is-invalid': submitted && $v.user.country.$error }">
                      <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                    </select>
                    <div v-if="submitted && $v.user.country.$error" class="invalid-feedback">
                      <span v-if="!$v.user.country.required">La selection du pays est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="name">Nom</label>
                    <input id="name" v-model="user.name" type="text" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.user.name.$error }"/>
                    <div v-if="submitted && $v.user.name.$error" class="invalid-feedback">
                      <span v-if="!$v.user.name.required">Le nom de l'utilisateur est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="email">Email</label>
                    <input id="email" v-model="user.email" type="email" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.user.email.$error }"/>
                    <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                      <span v-if="!$v.user.email.required">L'email de l'utilisateur est obligatoire.</span>
                      <span v-if="!$v.user.email.email">L'email n'est pas correct.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="password">Mot de pase</label>
                    <input id="password" v-model="user.password" type="password" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
                    <div v-if="submitted && $v.user.password.$error" class="invalid-feedback">
                      <span v-if="!$v.user.password.required">Le mot de passe est obligatoire.</span>
                      <span v-if="!$v.user.password.minLength">Le mot de passe doit avoir minimum 8 caractères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="confirmPassword">Confirmation mot de pase</label>
                    <input id="confirmPassword" v-model="user.confirmPassword" type="password" class="form-control"
                           :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }"/>
                    <div v-if="submitted && $v.user.confirmPassword.$error" class="invalid-feedback">
                      <span v-if="!$v.user.confirmPassword.required">Le mot de passe est obligatoire.</span>
                      <span v-if="!$v.user.confirmPassword.sameAsPassword">Le mot de passe doit être identique.</span>
                    </div>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary me-3" type="submit">Enregistrer</button>
              <button class="btn btn-secondary" type="reset" @click.prevent="clearForm">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des comptes administrateur</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="users"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-light">
                    Voir
                  </b-button>
                  <b-button size="sm" class="bg-warning mx-2" @click="user = row.item; editing = true">
                    Modifier
                  </b-button>
                  <b-button size="sm" class="bg-danger" @click="remove(row.item.id)">
                    Supprimer
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

